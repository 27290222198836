import { Row, Col, Radio, Modal, Table, Image,Card,TimePicker, Button,Switch, Tooltip, Checkbox, Form, Input, Select, InputNumber, DatePicker, Badge,Tag } from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { Link, useParams, useNavigate } from "react-router-dom";


import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import lang from "../../helper/lang";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import useApi from "../../hooks/useApi";
import moment from "moment";
import MultipleImageUpload from "../../components/MultipleImageUpload";
import AddForm from "../Table/AddForm";
const Option = Select;
const CheckboxGroup = Checkbox.Group;
const UserFrom = () => {
  const api = {
    // userList: apiPath.allUserList,
    editEvent: apiPath.editEvent,
    addEvent: apiPath.addEvent,
    venueList: apiPath.allVenues
  }

  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"];
  const [form] = Form.useForm();
  const params = useParams();
  const [searchText, setSearchText] = useState('');

  const { request } = useRequest();
  const [eventTable, setEventTable] = useState([]);
  const [recurringDate, setRecurringDate] = useState("");
  const [recurringDay, setRecurringDay] = useState("");
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [recurring, setRecurring] = useState("");
  const [floorImage, setFloorImage] = useState();

  const [image, setImage] = useState();
  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(false)
  const [menuImage, setMenuImage] = useState()

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [subAdmins, setSubadmins] = useState([]);

  const [eventType, setEventType] = useState();
  const [entryType, setEntryType] = useState([]);
  const [vendors, setVendor] = useState([]);
  const [countries, setCountries] = useState([])
  const [story, setStory] = useState([]);
  const { getCountry } = useApi(); const formData = form.getFieldsValue();

  // Now you can access formData to get guest list, ticket, table data
  const { guestList, ticket, table } = formData;

  const [venues, setVenues] = useState([]);

  const getVenues = () => {
    request({
      url: api.venueList,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
          console.log(data.data, 3222)
        }
        setVenues(data.data);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }
  const tableColumns = [
   
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id }) => {
        return (<a className="cap avatar-text" >{name ? name : 'Not Available'}</a>);
      },
      sorter: (a, b) => {
        let nameA = a.name?.toLowerCase();
        let nameB = b.name?.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
        },
    },
    
    
    {
      title: `${lang("Capacity")}`,
      dataIndex: "capicity",
      key: "capicity",
      render: (_, { capicity }) => {
        return (<a className="cap avatar-text">{capicity ? capicity : 'Not Available'}</a>);
      },
      },
      {
        title: `${lang("Min. Cost")}`,
      dataIndex: "min_cost",
      key: "min_cost",
      render: (_, { min_cost}) => {
        return (<a className="cap avatar-text">{min_cost ? min_cost : 'Not Available'}</a>);
      },
      },
      {
        title: `${lang("Max. Cost")}`,
        dataIndex: "max_cost",
        key: "max_cost",
        render: (_, { max_cost }) => {
        return (<a className="cap avatar-text">{max_cost ? max_cost : 'Not Available'}</a>);
        },
    },
    {
      title: `${lang("Reservation Fee")}`,
      dataIndex: "reservation_fee",
      key: "reservation_fee",
      render: (_, { reservation_fee }) => {
        return (<a className="cap avatar-text">{reservation_fee ? reservation_fee : 'Not Available'}</a>);
      }
      },
      {
        title: 'Table Status',
        key: 'table_status',
        dataIndex:'table_status',
        render: (_, {table_status, _id}) => {
          return <p >{table_status? "Booked": "Available"}</p>
          },
      },
    
      {
      title: `${lang("Status")}`,
      key: "is_active",
     
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        const type = "is_active";
        return (<Badge><Tag   color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></Badge>);
      },
    },
    
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    }
  ];
  const days = [
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' },
    { label: 'Sunday', value: 'Sunday' },

  ]
  const changeEventType = (value) => {
    setEventType(value)
    if (value == "Special") {
      setRecurring("");
      // setRecurringDate("");
      setRecurringDay("");
    }
  }
  const getVendors = () => {
        request({
            url: apiPath.listingVendors,
            method: "GET",
            onSuccess: (data) => {
                if (!data.status) {
                    console.log(data.data, 3222)
                }
                setVendor(data.data.data);
            },
            onError: (err) => {
                console.log(err);
            }
        })
    
  }
  const getSubadmins = () => {
    request({
      url: apiPath.subAdmins,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
          console.log(data.data, 3222)
        }
        setSubadmins(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }
  const handleVenueImage = (data) => {
    // setImage(data[0]?.url);
    setImage(data[0]?.url);
  }
  const handleMenuImage = (data) => {
    // setImage(data[0]?.url);
    setMenuImage(data[0]?.url);
  };
  const handleVenueStory = (data) => {
    // setImage(data[0]?.url);
    setStory(data.map((story)=> story.url));
  }

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewEvent + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        // setData(data.data);
        setStory(data?.data?.eventDetail?.stories)
        setEntryType(data?.data?.eventDetail?.entry_type)
        form.setFieldsValue({
          ...data?.data?.eventDetail,
          ...data?.data?.eventDetail?.tableData,
          ...data?.data?.eventDetail?.guestListData,
          ...data?.data?.eventDetail?.ticketData,
          country: data?.data?.eventDetail?.country_id,
          venue_id: data?.data?.eventDetail?.venue_id._id,
          vendor_id: data?.data?.eventDetail?.vendor_id._id,
          start_time:  moment(`${ moment().format('YYYY-MM-DD')} ${data?.data?.eventDetail?.start_time}`, 'YYYY-MM-DD HH:mm'),
          end_time:  moment(`${ moment().format('YYYY-MM-DD')} ${data?.data?.eventDetail?.end_time}`, 'YYYY-MM-DD HH:mm'),
          guest_admin_comission: data?.data?.eventDetail?.guestListData?.admin_commission,
          table_admin_comission: data?.data?.eventDetail?.tableData?.admin_commission,
          ticket_admin_comission: data?.data?.eventDetail?.ticketData?.admin_commission,
          table_time: data?.data?.eventDetail?.tableData&& data?.data?.eventDetail?.tableData.table_time && moment(data?.data?.eventDetail?.tableData.table_time),
          table_note: data.data.eventDetail.tableData?.note,
          guest_note: data.data.eventDetail.guestListData?.note,

          male_charges: data?.data?.eventDetail?.guestListData?.coverCharges?.weekdays?.male,
          female_charges: data?.data?.eventDetail?.guestListData?.coverCharges?.weekdays?.female,
          weekend_male_charges: data?.data?.eventDetail?.guestListData?.coverCharges?.weekends?.male,
          weekend_female_charges: data?.data?.eventDetail?.guestListData?.coverCharges?.weekends?.female,
          // max_capacity: data?.data?.eventDetail?.guestListData?.max_capacity,
          weekday_male_guestlist: data?.data?.eventDetail?.guestListData?.coverCharges?.weekdays
          ?.male_guestlist,
          weekday_female_guestlist: data?.data?.eventDetail?.guestListData?.coverCharges?.weekdays
          ?.female_guestlist,
          weekends_male_guestlist: data?.data?.eventDetail?.guestListData?.coverCharges?.weekends
          ?.male_guestlist,
          weekends_female_guestlist: data?.data?.eventDetail?.guestListData?.coverCharges?.weekends
          ?.female_guestlist,
          signUp_before: moment(data?.data?.eventDetail?.guestListData?.signUp_before),
          recurringDate: data?.data?.eventDetail?.recurringDate? moment(
            data?.data?.eventDetail?.recurringDate
          ): "",
          // recurringDay: moment(
          //   data?.data?.eventDetail?.recurringDay
          // ),
          arrive_before: moment(data?.data?.eventDetail?.guestListData?.arrive_before)

        });
        setSelected({
          venue_id:data?.data?.eventDetail?.venue_id._id,
          event_id: data?.data?.eventDetail?._id
        });
        setEventType(data?.data?.eventDetail?.event_type);
        setImage(data?.data?.eventDetail?.image);
        console.log(data?.data?.eventDetail?.recurringDate, "date>>>>>>>>>>>>",
          data?.data?.eventDetail?.recurringDay, "day>>>>>>>>>>>>"
        )
        setFloorImage(data.data.eventDetail.tableData?.table_layout);
        setMenuImage(data?.data?.eventDetail?.tableData?.menu);
        // if (data?.data?.eventDetail?.recurringDate) {
        //   setRecurring("Date")
        // } else if (data?.data?.eventDetail?.recurringDay) {
        //   setRecurring("Day")

        // }
        if (data?.data?.eventDetail?.recurringDate){

          setRecurringDate(moment(data?.data?.eventDetail?.recurringDate));
        }else if(data?.data?.eventDetail?.recurringDay){

          setRecurringDay(data?.data?.eventDetail?.recurringDay)
        }
        setSwitchState(data?.data?.eventDetail?.is_featured);

      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  const fetchEventTable = (id) => {
    request({
      url: apiPath.eventTable + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setEventTable(data.data.data)
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    getVenues();
    if (params.id) {
      fetchData(params.id)
      fetchEventTable(params.id)

    }
    getVendors()
    getSubadmins();

  }, [])

  useEffect(() => {
    // const {getCountry} = getCountry
    //  console.log(getCountry(),"getCountry")
    //  getCountry()
    getCountry({
      countryData: (data) => setCountries(data)
    });
  }, [])


  const onCreate = () => {
    // setLoading(true);
    console.log("jkdfioe>>>>>>>>>")
    const values = form.getFieldsValue();

    // Now you can access formData to get guest list, ticket, table data
    // const { guestList, ticket, table } = formData;
    const guestListData = {
      coverCharges: {
        weekdays: {
          male: values.male_charges,
          female: values.female_charges,
          male_guestlist: values.weekday_male_guestlist,
          female_guestlist: values.weekday_female_guestlist,
              },
        weekends: {
          male: values.weekend_male_charges,
          female: values.weekend_female_charges,
          male_guestlist: values.weekends_male_guestlist,
          female_guestlist: values.weekends_female_guestlist,

        }
      },
      max_capacity: values.max_capacity,
      signUp_before: values.signUp_before,
      arrive_before: values.arrive_before,
      male_admin_commission: values.male_admin_commission,
      female_admin_commission:  values.female_admin_commission,

      note: values.guest_note,
    }
    const tableData = {
      table_time: values.table_time,
      no_of_table: values.no_of_table,
      no_of_people: values.no_of_people,
      advanced_deposit: values.advanced_deposit,
      note: values.table_note,
      table_layout: floorImage,
      menu: menuImage,

      admin_commission: values.table_admin_comission,
    }

    const ticketData = {
      affiliate_link: values.affiliate_link,
      promo_codes: values.promo_codes,

      admin_commission: values.ticket_admin_comission,
    }
    let stories = []
    values?.stories?.map((story) => stories.push(story.url))


    let payload = {
      ...values,
      image: image?.length ? image : '',

      event_type: eventType,
      entry_type: entryType,
      guestListData: guestListData,
      tableData: tableData,
      ticketData: ticketData,
      stories: stories,
      start_time: values.start_time ? values.start_time.format("HH:mm") : null,
      end_time: values.end_time ? values.end_time.format("HH:mm") : null,
      // recurringDay: recurringDay,
      // recurringDate: recurringDate
    };
    
    // Clear one of the properties based on the condition
    if (recurringDay) {
      payload.recurringDay = recurringDay;
      payload.recurringDate = "";
    } else if (recurringDate) {
      payload.recurringDate = recurringDate;
      payload.recurringDay = "";
    }
    console.log(payload, 40111)
    request({
      url: `${params.id ? apiPath.addEditEvent + "/" + params.id : apiPath.addEditEvent}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate("/event")
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };
  const options = [
    { label: 'Table Reservation', value: 'Table' },
    { label: 'By Tickets', value: 'Tickets' },
    { label: 'Guest List', value: 'Guest List' },
  ];

  const [switchState, setSwitchState] = useState(false);

  const handleChange = checked => {
    console.log(`Switch is ${checked ? 'on' : 'off'}`);
    setSwitchState(checked);
  };
  const handleFloorImage = (data) => {
    console.log(data, "floor image----------->")
    setFloorImage(data[0].url);
  }

  return (
   
    <>
    <Card>
      <Form id="create" form={form} initialValues={{}} onFinish={onCreate} layout="vertical">
        <Row gutter={[24, 16]} style={{padding:"20px"}}>
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Event Name"
              name="name"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the event name!" },
                { max: 200, message: "Event Name should not contain more then 200 characters!" },
                { min: 2, message: "Event Name should contain at least 2 characters!" },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Event Name" />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Venue Name"
              name="venue_id"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select venue!" },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                showSearch  // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                placeholder="Select Venue"
                onChange={(value) => form.setFieldValue("venue_id", value)}
                options={venues.map(v => ({
                  label: v?.name,
                  value: v?._id
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Vendor Name"
              name="vendor_id"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select vendor!" },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                showSearch  // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                placeholder="Select Vendor"
                onChange={(value) => form.setFieldValue("vendor_id", value)}
                options={vendors.map(v => ({
                  label: v?.name,
                  value: v?._id
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Sub-Admin Name"
              name="subadmin_id"
              normalize={value => value.trimStart()}
              
            >
              <Select
                style={{
                  width: '100%',
                }}
                showSearch  // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                placeholder="Select Sub-admins"
                onChange={(value) => form.setFieldValue("subadmin_id", value)}
                options={subAdmins.map(v => ({
                  label: v?.name,
                  value: v?._id
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={12} sm={12}>
              <Form.Item
                label="Start Time"
                name="start_time"
                rules={[
                  { required: true, message: "Please select event date and time!" },
                ]}
              >
                <TimePicker format="HH:mm" minuteStep={30} use12Hours={true} />
              </Form.Item>
            </Col>

            <Col span={24} lg={12} sm={12}>
              <Form.Item
                label="End Time"
                name="end_time"
                className="start-and-end0t"
                rules={[
                  { required: true, message: "Please select event date and time!" },
                ]}
              >
                <TimePicker format="HH:mm" minuteStep={30} use12Hours={true} />
              </Form.Item>
            </Col>

            <Col span={24} lg={12} sm={12}>
              <Form.Item className="mb-1"
                label="Event Description"
                name="description"
                normalize={value => value.trimStart()}
                rules={[
                  { required: true, message: "Please Enter the event description!" },
                  { max: 200, message: "Event Description should not contain more then 200 characters!" },
                  { min: 2, message: "Event Description should contain at least 2 characters!" },
                ]}
              >
                <Input autoComplete="off" placeholder="Enter Event Description" />
              </Form.Item>
            </Col>

            <Col span={24} sm={12}>
              <Form.Item
                label="Event Type"
                name="event_type"
                className="mb-0"
                // rules={[
                //   { required: true, message: "Please Select Event Type!" },
                // ]}
              >
                {console.log(eventType, 51444)}
                <Radio.Group value={eventType} onChange={(e) => changeEventType(e.target.value)}>
                  <Tooltip title="Events that happen on a regular schedule, like weekly or monthly.">
                    <Radio value={"Recurring"}>Recurring</Radio>
                  </Tooltip>
                  <Tooltip title="One-time events that do not repeat.">
                    <Radio value={"Special"}>Special</Radio>
                  </Tooltip>
                </Radio.Group>
              </Form.Item>
              <div className="d-flex gap-3">
                {/* {eventType == "Recurring" ?
                  <Form.Item className="mb-0 w-100">
                    {console.log(recurring, "reverse>>>>>>>")}
                    <Radio.Group onChange={(e) => setRecurring(e.target.value)} defaultValue={recurring}>
                      <Tooltip title="Events that happen on a regular schedule, like weekly or monthly.">
                        <Radio value={"Day"}>Day</Radio>
                      </Tooltip>
                      <Tooltip title="One-time events that do not repeat.">
                        <Radio value={"Date"}>Date</Radio>
                      </Tooltip>
                    </Radio.Group>

                  </Form.Item> : <DatePicker

                    format="YYYY-MM-DD"
                    style={{ width: '100%' }}
                    onChange={(value) => setRecurringDate(value)}
                    defaultValue={recurringDate}
                  />} */}
                 {eventType == "Recurring" ?
                  <Form.Item name={"recurringDay"} className="mb-0 w-100">

                    <Select
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select Day"
                      onChange={(value) => setRecurringDay(value)}
                      value={recurringDay}
                      options={days.map(v => ({
                        label: v?.name,
                        value: v?.value
                      }))}
                      /> 
                      </Form.Item>
                    
                    :  <Form.Item name={"recurringDate"} className="mb-0 w-100">
                      <DatePicker

format="YYYY-MM-DD"
                    style={{ width: '100%' }}
                    onChange={(value) => setRecurringDate(value)}
                    defaultValue={recurringDate}
                    />
                    </Form.Item>
                    }
              </div>
            </Col>


            <Col span={24} sm={12}>
              <Form.Item
                label="Entry Type"
                name="entry_type"
                // rules={[
                //   { required: true, message: "Please Select Entry Type!" },
                // ]}
              >
                <CheckboxGroup options={options} onChange={(e) => setEntryType(e)} />

              </Form.Item>
            </Col>

            <Col span={24} sm={12}>
              <Form.Item
                label="DJ Name"
                name="dj_type"
                // rules={[
                //   { required: true, message: "Please Select Event Name!" },
                // ]}
              >
               <Input placeholder="Enter DJ Name"/>

              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item
                label="Music Type"
                name="music_type"
                // rules={[
                //   { required: true, message: "Please Select Event Type!" },
                // ]}
              >
               <Input placeholder="Enter music Type"/>

              </Form.Item>
            </Col>


            <Col span={24} sm={12}>
              <Form.Item
                label="Featured"
                name="is_featured"

              >
                <Switch
                  checked={switchState}
                  onChange={(checked) => setSwitchState(checked)}
                />
              </Form.Item>
            </Col>


            <Col span={24} sm={12}>
              <Form.Item label={"Upload Event Image (Width : 1130 x Height : 640)"} name="image"
              >
                <SingleImageUpload value={image} fileType={FileType} imageType={'venue'} btnName={'Event Image'} onChange={(data) => handleVenueImage(data)} />
              </Form.Item>

              {image && image.length > 0 &&
                <div className="mt-2">
                  <Image width={100} src={image && image.length > 0 && image !== "" ? image : notfound}></Image>
                </div>
              }

            </Col>

            

            {!params.id ? <Col span={24} sm={24}>
              <Form.Item label={"Upload Event Story (Width : 1130 x Height : 640)"} name="stories"
              >
                <MultipleImageUpload value={story} fileType={FileType} imageType={'story'} btnName={'Event Stories'} onChange={(data) => handleVenueStory(data)} />
              </Form.Item>

              {/* {story && story.length > 0 &&
                story.map((sto, index) => (
                  <div key={index} className="mt-4">
                    {console.log(sto, 99999)}
                    <img
                      width={100}
                      src={sto && sto.length > 0 && sto !== "" ? sto : notfound}
                      alt={`Image ${index}`}
                    />
                  </div>
                ))
              } */}
            </Col> : ""}
            {entryType?.includes("Guest List") ?
              <Row gutter={[24, 16]} className="border-tikts">
                <Col span={24}>
                  <h3>Guest List Fields</h3>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="WeekDays Male Charges(weekdays without guestlist in $)"
                    name="male_charges"
                   
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Weekdays Female Charges(weekdays without guestlist in $)"
                    name="female_charges"
                   
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Weekends Male Charges(weekends without guestlist in $)"
                    name="weekend_male_charges"
                   
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Weekends Female Charges(weekends without guestlist in $)"
                    name="weekend_female_charges"
                    
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Weekday Male guestlist charges(in $)"
                    name="weekday_male_guestlist"
                   
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Weekday Female guestlist charges(in $)"
                    name="weekday_female_guestlist"
                    
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Weekend male guestlist charges(for guestlist in $)"
                    name="weekends_male_guestlist"
                   
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Weekend female guestlist charges(for guestlist in $)"
                    name="weekends_female_guestlist"
                   
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>

                <Col span={24} sm={12}>
                  <Form.Item
                    label="Max. Capacity"
                    name="max_capacity"
                    rules={[
                      { required: true, message: "Please enter max no. of capacity!" },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter number........" />
                  </Form.Item>
                </Col>
               
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Sign-up before"
                    name="signUp_before"
                    rules={[
                      { required: true, message: "Please add sign-up time!" },
                    ]}
                  >
                    <TimePicker format="HH:mm" minuteStep={30} use12Hours={true} />              
                      </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Arrive before"
                    name="arrive_before"
                    rules={[
                      { required: true, message: "Please add arriving time!" },
                    ]}
                  >
                    <TimePicker format="HH:mm" minuteStep={30} use12Hours={true} />               
                     </Form.Item>
                </Col>
               
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Male Admin Commision"
                    name="male_admin_commission"

                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Female Admin Commision"
                    name="female_admin_commission"

                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Note(max. 100 limit)"
                    name="guest_note"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || value.length <= 100) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Note must be 100 characters or less!'));
                        },
                      }),
                    ]}
                  >
                    <Input maxLength={100} autoComplete="off" placeholder="Enter note" />
                  </Form.Item>
                </Col>

              </Row>
              : ""}
            {entryType?.includes("Tickets") ?
              <Row gutter={[24, 16]} className="border-tikts">
                <Col span={24}>
                  <h3>Tickets Fields</h3>
                </Col>

                <Col span={24} sm={12}>
                  <Form.Item
                    label="Affiliate Link"
                    name="affiliate_link"
                    rules={[
                      { required: true, message: "Please add affiliate link!" },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter link" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Promo Codes"
                    name="promo_codes"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || value.length <= 10) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Promo codes must be 10 characters or less!'));
                        },
                      }),
                    ]}
                  >
                    <Input maxLength={10} autoComplete="off" placeholder="Enter promo codes........" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Admin Commision(in %)"
                    name="ticket_admin_comission"

                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>

              </Row>
              : ""}
            {entryType?.includes("Table") ?
              <Row gutter={[24, 16]} className="border-tikts">
                <Col span={24}>
                  <h3>Table Fields</h3>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Time for table reservation"
                    name="table_time"
                    rules={[
                      { required: true, message: "Please add time!" },
                    ]}
                  >
                    <TimePicker  minuteStep={30} use12Hours={true} format="HH:mm"  />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="No. of Table"
                    name="no_of_table"
                    rules={[
                      { required: true, message: "Please add number!" },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter number" />
                  </Form.Item>
                </Col>
                <Col span={12}  sm={12}>
            <Form.Item label={"Upload floor  Image (Width : 1130 x Height : 640)"} name="floor_img"
              rules={[
                // {
                //   validator: (_, value) => {
                //     if (value !== undefined && value?.length > 0) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject(new Error('Venue Image is required'));
                //   },
                // }
              ]}>
              <SingleImageUpload value={floorImage} fileType={FileType} imageType={'tableFloor'} btnName={'Floor Image'} onChange={handleFloorImage} />
            </Form.Item>

            {floorImage && floorImage.length > 0 &&
              <div className="mt-2">
                <Image width={100} src={floorImage && floorImage.length > 0 && floorImage !== "" ? floorImage : notfound}></Image>
                {/* <Button title="Delete" onClick={() => setFloorImage([])}>
                <i className="fas fa-trash"></i>
              </Button> */}
              </div>
            }
          </Col>

          <Col>
            <Form.Item>

          <SingleImageUpload
                  value={menuImage}
                  fileType={FileType}
                  imageType={"menue"}
                  btnName={"Menu"}
                  onChange={(data) => handleMenuImage(data)}
                />
              </Form.Item>

              {menuImage && menuImage.length > 0 && (
                <div className="mt-2">
                  <Image
                    width={100}
                    src={
                      menuImage && menuImage.length > 0 && menuImage !== ""
                        ? menuImage
                        : notfound
                    }
                  ></Image>
                </div>
              )}
            </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Note(max. 100 limit)"
                    name="table_note"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || value.length <= 100) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Not must be less than 100 characters!'));
                        },
                      }),
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter note........" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label="Admin Commision(in %)"
                    name="table_admin_comission"

                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col >

                                <Col span={24} sm={24} className="mainaddtable-buttonmain9900">
                                {params.id ? <>  <Button className="mainaddtable-button" onClick={(e) => { setVisible(true);  setSearchText(''); }}>Add Table</Button>
                                    <Table
                                    loading={loading}
                                    columns={tableColumns}
                                    dataSource={eventTable}
                                    pagination={{
                                      defaultPageSize: 10,
                                      responsive: true,
                                      showSizeChanger: true,
                                      pageSizeOptions: ['10', '20', '30', '50']
                                    }}
                                    className="ant-border-space"
                                  /></>
                                  :""}
                                
                                
                                </Col>

              </Row>
              : ""}
               <div className="view-inner-cls float-right float-rightmain-89">
              <Link className="ant-btn ant-btn-primary" to={`/event`}>{lang("Back")}</Link>
            </div>
            <div className="view-inner-cls float-right">
              <Button htmlType="submit" className="ant-btn ant-btn-primary">{lang("Save")}</Button>
            </div>
        
        </Row>
      </Form>
      </Card>
       {visible && <AddForm type={"Table"} path={api.addEdit} sectionName={"Table"} show={visible} hide={() => {  setVisible(false); fetchEventTable(params.id) }}  selected={selected} refresh={() => setRefresh(prev => !prev)} />} 

    </>
    // </Modal >
  )
};

export default UserFrom;