import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Tooltip, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import AddForm from "./AddForm";
import ConfirmationBox from "../../components/ConfirmationBox";
import ConfirmationDelete from "../../components/ConfirmationDelete";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import lang from "../../helper/lang";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
  const permission= userProfile.user_type== "SubAdmin"?userProfile.permission["Access Guests"]:undefined;

  const sectionName = lang("Guest")+" "+lang("Lists");
  const routeName = "guest-list";

  const api = {
    status: apiPath.tableStatus,
    delete: apiPath.deleteGuest,
    addEvent: apiPath.addEditEvent,
    list: apiPath.guestList,
    importFile: apiPath.importEvent
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const { confirmDelete } = ConfirmationDelete()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const columns = [  
    {
      title: `${lang("Booking Id")}`,
      dataIndex: "bookings",
      key: "bookings",
      render: (_, { bookings, _id }) => {
        return (<a className="cap avatar-text">{bookings ? bookings?.booking_id : 'Not Available'}</a>);
      },
    },  
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { user_id, _id }) => {
        return (<a className="cap avatar-text" >{user_id ? user_id?.name : 'Not Available'}</a>);
      }
    },
    {
      title: `${lang("Email")}`,
      dataIndex: "email",
      key: "email",
      render: (_, { user_id, _id }) => {
        return (<a className="cap avatar-text" >{user_id ? user_id?.email : 'Not Available'}</a>);
      }
    },
    {
      title: `${lang("Event Name")}`,
      dataIndex: "event_id",
      key: "event_id",
      render: (_, { event_id, _id }) => {
        return (<a className="cap avatar-text">{event_id ? event_id?.name : 'Not Available'}</a>);
      },
    },
    {
      title: `${lang("Total")} ${lang("Guests")}`,
      dataIndex: "total_guest",
      key: "total_guest",
      render: (_, { total_guest }) => {
        return (<a className="cap avatar-text">{total_guest ? total_guest : 0}</a>);
      },
    },
    {
      title: `${lang("Checked")} ${lang("User")}`,
      dataIndex: "checked_user",
      key: "checked_user",
      render: (_, { checked_user}) => {
        return (<a className="cap avatar-text">{checked_user ? checked_user : 0}</a>);
      },
    },
    {
      title: `${lang("CheckIn")} ${lang("Date")}${lang("Time")}`,
      dataIndex: "check_in_time",
      key: "check_in_time",
      render: (_, { check_in_time }) => {
        return (
          moment(check_in_time).format('DD-MMM-YY hh:mm A')
        );
      },
    },
   
    {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>{userProfile.user_type=="Admin" ||permission?.includes("edit") ? 
            <>
            <Tooltip title={`${lang('Update')} ` + sectionName} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
              </Tooltip>
              <Tooltip title={`${lang('Delete')} ` + sectionName} color={"purple"} key={"update" + routeName}>

              <Button title="Delete" onClick={() => confirmDelete({ record: record._id, path: api.delete, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>
                <i className="fas fa-trash"></i>
              </Button>
            </Tooltip>

            {/* <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip> */}

          </>: ""}</>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    const filterVerify = filters ? filters.is_verify : null;

    request({
      url: api.list + `?verify=${filterVerify ? filterVerify.join(',') : ''}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.totalDocs, pageSize: pagination.pageSize }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);

  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    // setPagination({ current: 1 })
  };



  const excelData = list.map(row => ({
    "Name": row.user_id ? row.user_id.name : '-',
    "Event Name": row.event_id ? row.event_id.name : '-',
    "Total Guests": row.total_guest ? row.total_guest : '-',
    "CheckedIn User": row.checked_user ? row.checked_user : '-',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  }));
  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName }
              extra={
                <>
                  <div className="searchOuter">
                    <Search className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Search")} ${lang("By")} Guest Name, Event Name`}
                    />
                    {/* <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} /> */}
                  </div>
                  <div className="button_group">
{userProfile.user_type=="Admin" || 
        permission?.includes("add")?                     <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>{lang("Add")} {sectionName}</Button>:""
}                    <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>

                  </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ 
                    ...pagination,
                    defaultPageSize: 10,
                     responsive: true, 
                    //  total: pagination.total, 
                     showSizeChanger: true, 
                     pageSizeOptions: ['10', '20', '30', '50'] 
                    }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddForm type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      {/* {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />} */}

    </>
  );
}
export default Index;
