import { Row, Col, Card, Button, Switch, Input, Form, Skeleton, TimePicker, Image, Select, Radio, InputNumber, Space, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import moment from 'moment';
import DescriptionEditor from '../../components/DescriptionEditor'
import { MAXPRODUCTLIMITTYPE, PRICE, SUBSCRIPTIONOFFEREDTYPE, USERTYPE } from "../../constants/conts";
import LocationMap from "../User/LocationMap";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

function Add() {

    const sectionName = "Venue";
    const routeName = "venue";

    const api = {
        add: apiPath.addEditVenue,
        category: apiPath.categoryList,
        subCategory: apiPath.subCategoryList,
        product: apiPath.productList,
        venue: apiPath.venueById,
        // edit:apiPath.ve
    }

    const numbers = Array.from({ length: 30 }, (_, index) => index + 1);

    const [form] = Form.useForm();
    const { request } = useRequest()
    const params = useParams();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]
    const [image, setImage] = useState([]);
    const [file, setFile] = useState([]);
    const [thumbNail, setThumbnail] = useState("")

    const [subAdmins, setSubadmins] = useState([]);
    const [location, setLocation] = useState();
    const [tagsCount, setTagsCount] = useState();
    const [selectedMusicTypes, setSelectedMusicTypes] = useState([]);
    const [switchState, setSwitchState] = useState(false);
    const [vendors, setVendor] = useState([])
    const [logo, setLogo] = useState([])
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const handleMusicType = (values) => {
        setSelectedMusicTypes(values);
    };

    const handleStartTimeChange = (time, timeString) => {
        setStartTime(timeString);
    };

    const handleEndTimeChange = (time, timeString) => {
        setEndTime(timeString);
    };

    const fetchData = (id) => {
        request({
            url: api.venue + "/" + id,
            method: 'GET',
            onSuccess: ({ data, status }) => {
                setLoading(false);

                form.setFieldsValue({ ...data, product_auto_deactivate_date: moment(data.product_auto_deactivate_date), validity_date: moment(data.validity_date) })

                // setSubscriptionData(data);
                // setUserAddress(data?.offered_location?.address);
                // setProdutLimitType(data?.max_product_limit_type);
                // setSubscriptionOfferedType(data?.subscription_offered_type);
                // setlatLong({ lat: parseFloat(data?.offered_location?.latitude), lng: parseFloat(data?.offered_location?.longitude) });
                setFile([data.image])
            },
            onError: (error) => {
                ShowToast(error, Severty.ERROR)
            }
        })
    }



    const handleLogo = (data) => {
        console.log(data, "data>>>>>>>>>>.")
        setLogo(data[0].url);
    }
    const handleImage = (data) => {
        console.log(data, "data>>>>>>>>>>.")
        setImage(data[0].url);
        setThumbnail(data[0].thumbUrl);

    }

    const OnUpdate = (values) => {
        console.log(values, 922)
        const payload = {
            ...values,
            image: image,
            logo: logo,
            map_icon: thumbNail,

            location: values.location.location,
            longitude: values.location.longitude,
            latitude: values.location.latitude,
            is_featured: switchState,
            // validaty_date: moment(values.validatyDate).format("YYYY-MM-DD"),
            // product_auto_deactivate_date: moment(values.product_auto_deactivate_date).format("YYYY-MM-DD"),
            // validaty_subscription: moment(values.validaty_subscription).format("YYYY-MM-DD"),
        };

        payload['start_time'] = moment(payload.start_time).format("HH:mm");
        payload['end_time'] = moment(payload.end_time).format("HH:mm")
        if ((payload.start_time.split(":")[0]).length < 2) {
            payload['start_time'] = 0 + payload.start_time
        }
        if ((payload.end_time.split(":")[0]).length < 2) {
            payload['end_time'] = 0 + payload.end_time
        }
        setLoading(true)
        request({
            url: api.add,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    const handleLocationChange = (value) => {
        setLocation(value);
    }

    const crowdType = ["Young Professionals",
        "College Students",
        "Tourists",
        "Social Butterflies",
        "Couples",
        "Regulars",
        "Older",
        "Fashionistas"
    ]
    const musicType = ["Hip Hop",
        "Jazz",
        "Pop",
        "Reggae",
        "Punk",
        "Classical",
        "Rock",
        "Electronic",
        "Latin"]



    const getVendors = () => {
        request({
            url: apiPath.listingVendors,
            method: "GET",
            onSuccess: (data) => {
                if (!data.status) {
                    console.log(data.data, 3222)
                }
                setVendor(data.data.data);
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const getSubadmins = () => {
        request({
            url: apiPath.subAdmins,
            method: "GET",
            onSuccess: (data) => {
                if (!data.status) {
                    console.log(data.data, 3222)
                }
                setSubadmins(data.data.data);
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    useEffect(() => {
        getVendors();
        getSubadmins()
    }, [])
    const indicator = ["$", "$$", "$$$", "$$$$"];

    return (

        <>
            <Form className="edit-page-wrap colPadding" initialValues={{ products: [{ product_id: undefined, qty: undefined }], lead_time: 20, delivery_radius: 20, is_change_meal: "no" }} form={form} onFinish={OnUpdate} autoComplete="off" layout="verticle" name="subscription_form">

                <Card title={"Add " + sectionName}>
                    <Row gutter={[24, 0]}>

                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item normalize={value => value.trimStart()} label="Name" name="name" rules={[{ required: true, message: "Enter subscription name!" }]}>
                                <Input autoComplete="off" placeholder="Enter Venue Name" />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={12} sm={12}>
                            <Form.Item className="mb-1"
                                label="Vendor Name"
                                name="vendor_id"
                                normalize={value => value.trimStart()}
                                rules={[
                                    { required: true, message: "Please select vendor!" },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch  // Enable search functionality
                                    filterOption={(inputValue, option) =>
                                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                    placeholder="Select Vendor"
                                    onChange={(value) => form.setFieldValue("vendor_id", value)}
                                    options={vendors.map(v => ({
                                        label: v?.name,
                                        value: v?._id
                                    }))}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} lg={12} sm={12}>
                            <Form.Item className="mb-1"
                                label="Sub-Admin Name"
                                name="subadmin_id"
                                normalize={value => value.trimStart()}
                               
                            >
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch  // Enable search functionality
                                    filterOption={(inputValue, option) =>
                                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                    placeholder="Select Sub-admins"
                                    onChange={(value) => form.setFieldValue("subadmin_id", value)}
                                    options={subAdmins.map(v => ({
                                        label: v?.name,
                                        value: v?._id
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item normalize={value => value.trimStart()} label="Expense Indicator" name="expense_indicator" rules={[{ required: true, message: "Enter Icon or  Symbol" }]}>
                                <Select
                                    style={{
                                        width: "100%",
                                    }}
                                    showSearch // Enable search functionality
                                    filterOption={(inputValue, option) =>
                                        option.label
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                    }
                                    placeholder="Select expense indicator"
                                    onChange={(value) =>
                                        form.setFieldValue("expense_indicator", value)
                                    }
                                    options={indicator.map((v) => ({
                                        label: v,
                                        value: v,
                                    }))}
                                />
                                                         </Form.Item>
                        </Col>

                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item
                                /* rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value !== undefined && value?.length > 0) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Missing Image!'));
                                        },
                                    }
                                ]} */
                                label="Upload Logo" name="logo">

                                <SingleImageUpload value={logo} fileType={FileType} imageType={'venue'} btnName={'Image'} onChange={(data) => handleLogo(data)} />

                                {logo && logo.length > 0 && <div className="mt-2"> <Image width={120} src={logo !== "" ? logo : notfound}></Image> </div>}
                            </Form.Item>
                        </Col>


                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item className="music-type"
                                name="music_type"
                                label="Music Types"
                                rules={[{ required: true, message: 'Missing Music Type Selection' }]}
                            >
                                {/* <Select
                style={{
                  width: '100%',
                }}
                showSearch  // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                placeholder="Select music"
                onChange={(value) => form.setFieldValue("music_type", value)}
                options={musicType.map(v => ({
                  label: v,
                  value: v
                }))}
              />  */}
                                <Input placeholder="Enter music type" />


                            </Form.Item>

                        </Col>
                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item className="dress-code"
                                name="dress_code"
                                label="Dress Code"
                                rules={[{ required: true, message: 'Missing Dress Code' }]}
                            >
                                <Input placeholder="Enter dress code..." />

                            </Form.Item>

                        </Col>
                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item
                                label="Start Time"
                                name="start_time"
                                rules={[{ required: true, message: 'Please select a start time' }]}
                            >
                                <TimePicker style={{ width: "100%", height: "42px", borderRadius: "6px" }}
                                    format="hh:mm a"
                                    onChange={handleStartTimeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item
                                label="End Time"
                                name="end_time"
                                rules={[{ required: true, message: 'Please select an end time' }]}
                            >
                                <TimePicker TimePicker style={{ width: "100%", height: "42px", borderRadius: "6px" }}
                                    format="hh:mm a"
                                    onChange={handleEndTimeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12}>
                            <Form.Item
                                label="Featured"
                                name="is_featured"

                            >
                                <Switch
                                    checked={switchState}
                                    onChange={(checked) => setSwitchState(checked)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item label="Crowd Type" name="crowd_type" rules={[{ required: true, message: "MissingCrowd Type Description!" }]}>

                                {/* <Select
                style={{
                  width: '100%',
                }}
                showSearch  // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                placeholder="Select Crowd"
                onChange={(value) => form.setFieldValue("crowd_type", value)}
                options={crowdType.map(v => ({
                  label: v,
                  value: v
                }))}
              />  */}
                                <Input maxLength={100} autoComplete="off" placeholder="Enter Crowd Type " />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item normalize={value => value.trimStart()} label="Cover charges" name="cover_charges" rules={[{ required: true, message: "Missing Charges!" }]}>
                                <Input maxLength={180} autoComplete="off" placeholder="Enter charges" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>

                            <Form.Item className="Tags"
                                name="tags"
                                label="Tags"
                            >
                                <Select
                                    mode="tags"

                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch  // Enable search functionality

                                    placeholder="Select tags"
                                    onChange={(value) => form.setFieldValue("tags", value)}

                                />
                                {/* <Input placeholder="Enter music type" /> */}

                            </Form.Item>

                        </Col>

                        <Col span={24} sm={24} md={24} lg={24} xl={24} xxl={18}>
                            <Form.Item normalize={value => value.trimStart()} label="Bio (100 Characters)" name="bio" rules={[
                                { required: true, message: "Missing Description!" },
                                {
                                    validator: (_, value) => {
                                        if (value && value.trim().length > 100) {
                                            return Promise.reject('Bio should not exceed 100 characters');
                                        }
                                        return Promise.resolve();
                                    },
                                }]}>
                                <Input maxLength={180} autoComplete="off" placeholder="Enter Description" />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={24} md={24} lg={24} xl={24} xxl={18}>
                            <Form.Item normalize={value => value.trimStart()} label="Highlight/Detailed Introduction (100 Characters)" name="detailed_description" rules={[
                                { required: true, message: "Missing Description!" },
                                {
                                    validator: (_, value) => {
                                        if (value && value.trim().length > 100) {
                                            return Promise.reject('Introduction should not exceed 100 characters');
                                        }
                                        return Promise.resolve();
                                    },
                                }]}>
                                <Input maxLength={180} autoComplete="off" placeholder="Enter Description" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item
                                /* rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value !== undefined && value?.length > 0) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Missing Image!'));
                                        },
                                    }
                                ]} */
                                label="Upload Image" name="image">

                                <SingleImageUpload value={image} fileType={FileType} imageType={'venue'} btnName={'Image'} onChange={(data) => handleImage(data)} />

                                {image && image.length > 0 &&
                                    <div className="mt-2">
                                        {console.log(file, 999)}
                                        <Image width={100} src={image && Image.length > 0 && image !== "" ? image : notfound}></Image>
                                    </div>
                                }
                                {/* {file && file.length > 0 && <div className="mt-2"> <Image width={120} src={file !== "" ? file : notfound}></Image> </div>} */}
                            </Form.Item>
                        </Col>
                        <Col md={24}>
                            <Form.Item
                                label="Location" // (Drag Marker for Selecting Location)
                                name="location"
                                rules={[
                                    { required: true, message: "Please select the location!" },
                                ]}
                            >
                                <LocationMap onChange={handleLocationChange}
                                    editLocation={location}
                                // userData={data}  
                                //   type={type} 
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Card>



                <Form.Item className="btn-row float-right mb-0 mt-3">
                    <span onClick={() => window.history.back()}>
                        <Link className="ant-btn ant-btn-primary">Back</Link>
                    </span>                        <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default Add;