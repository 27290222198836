import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ConfirmationBox from "../../components/ConfirmationBox";

import ShowTotal from "../../components/ShowTotal";
import { BOOKING_STATUS, PRICE } from "../../constants/conts";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import lang from "../../helper/lang";
const { Option } = Select;
const Search = Input.Search;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function Index() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  const permission =
    userProfile.user_type == "SubAdmin"
      ? userProfile.permission.BookingSection
      : undefined;

  const sectionName = lang("Booking");
  const routeName = "booking";

  const api = {
    list: apiPath.bookingList,
    status: apiPath.bookingStatus,
  };
  const { showConfirm } = ConfirmationBox();

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [fullOrderDetails, setFullOrderDetails] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [status, setStatus] = useState("all");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const view = (id) => {
    navigate(`/booking/on-demand/view/${id}`);
  };

  const columns = [
    {
      title: `${lang("Booking")} ${lang("ID")}`,
      render: (_, { booking_id, _id }) => {
        return booking_id ? <p>{booking_id}</p> : "-";
      },
    },
    {
      title: `${lang("Customer")} ${lang("Detail")}`,
      dataIndex: "customers",
      key: "customers",
      width: 280, // Width 200px
      render: (customers) => {
        const { name, email, country_code, mobile_number } = customers || {};
        const countryCode = country_code ? `+${country_code}-` : "+968";
        const mobileNumber = mobile_number || "";

        return customers ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Email : {email}</div>
            <div>
              Mobile : {countryCode}
              {mobileNumber}
            </div>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: `${lang("Venue")} ${lang("Name")}`,
      dataIndex: "venue",
      key: "venue",
      width: 280,
      render: (venue) => {
        const { name, location } = venue || {};

        return venue ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Location : {location}</div>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: `${lang("Event")} ${lang("Name")}`,
      dataIndex: "event",
      width: 230,
      key: "event",
      render: (_, { events }) => {
        const { name, timings, tag, date } = events || {};

        return events ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Timings : {timings}</div>
            <div>Date : {date}</div>
            <div>Tag : {tag}</div>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: `${lang("Booking Time")}`,
      dataIndex: "bookingTime",
      key: "bookingTime",
      render: (_, { created_at }) => {
        return created_at ? (
          <span className="cap">{moment(created_at).format("hh:mm A")}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: `${lang("Amount Paid")} ($)`,
      dataIndex: "total_amount",
      key: "total_amount",
      render: (_, { total_amount }) => {
        return total_amount ? <span className="cap">{total_amount}</span> : "-";
      },
    },
    {
      title: "Payment Mode",
      dataIndex: "payment_mode",
      key: "payment_mode",
      filters: [
        {
          text: "Online",
          value: "online",
        },
        {
          text: "Cod",
          value: "Cod",
        },
      ],
      render: (_, { payment_mode }) => {
        let color = payment_mode == "Online" ? "gold" : "cyan";
        return (
          <Tag color={color} key={payment_mode}>
            {" "}
            {payment_mode}{" "}
          </Tag>
        );
      },
    },
    {
      title: "Checked-In Status",
      key: "checkedIn",
      render: (_, { _id, checking_status }) => {
        let color = checking_status ? "green" : "red";
        const type = "checkedIn";

        return (
          <a>
            <Tag
              onClick={(e) =>
                showConfirm({
                  record: _id,
                  path: api.status,
                  onLoading: () => setLoading(true),
                  onSuccess: () => setRefresh((prev) => !prev),
                  type,
                })
              }
              color={color}
              key={status}
            >
              {checking_status ? "CheckedIn" : "Not CheckedIn"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: `${lang("Status")}`,
      key: "status",
      render: (_, { status, _id }) => {
        let color =
          status === BOOKING_STATUS.new
            ? "yellow"
            : status === BOOKING_STATUS.checkedIn
            ? "blue"
            : status === BOOKING_STATUS.completed
            ? "green"
            : status === BOOKING_STATUS.cancelled
            ? "red"
            : "blue";
        const type = "status";

        return (
          <a>
            <Select
              value={status}
              style={{ width: 120 }}
              onChange={(value) => {
                if (
                  userProfile.user_type == "Admin" ||
                  permission.includes("edit")
                ) {
                  showConfirm({
                    record: _id,
                    path: api.status,
                    onLoading: () => setLoading(true),
                    onSuccess: () => setRefresh((prev) => !prev),
                    type: `${type}&status=` + value,
                  });
                }
              }}
            >
              <Option value="new">New</Option>
              <Option value="completed">Completed</Option>
              <Option value="cancelled">Cancelled</Option>
            </Select>

            {/* <Tag onClick={(e) => showConfirm({
               record: _id, path: api.status, 
               onLoading: () => setLoading(true), 
               onSuccess: () => setRefresh(prev => !prev), 
               type })}  color={color} key={status}>
              {status}
            </Tag> */}
          </a>
        );
      },
    },
    {
      title: `${lang("Booked_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("MM-DD-YYYY");
      },
      sorter: (a, b) => {
        if (a.created_at < b.created_at) {
          return -1;
        } else if (a.created_at > b.created_at) {
          return 1;
        }
        return 0;
      },
    },
  ];

  const fetchData = (pagination, filters, orderStatus) => {
    const filterPaymentMode = filters ? filters.payment_mode : null;
    const paymentDoneFilter = filters ? filters.paid : null;
    console.log(pagination, "pagnagt");
    setLoading(true);
    request({
      url:
        api.list +
        `?page=${pagination ? pagination.current : 1}&limit=${
          pagination ? pagination.pageSize : 10
        }&status=${
          orderStatus && orderStatus !== "all" ? orderStatus : ""
        }&search=${debouncedSearchText}&start_date=${
          startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data.list.docs);
        // setFullOrderDetails(data.data.orderedProducts);
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.list.totalDocs,
        }));
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters, status);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };

  const handleTabChange = (status) => {
    setStatus(status);
    fetchData(pagination, "", status);
  };

  useEffect(() => {
    setLoading(true);
    fetchData(pagination, "", status);
  }, [refresh, debouncedSearchText, startDate, endDate]);

  const excelData = list.map((row) => ({
    "Booking Id": row.booking_id ? row.booking_id : "-",

    "Customer Detail": row.customers
      ? `Name : ${row.customers.name}
      Email : ${row.customers.email}
              Mobile : ${row.customers.countryCode}
              ${row.customers.mobileNumber}
           `
      : "-",
    "Venue Name": row.venue
      ? `Name : ${row.venue.name}
      Location : ${row.venue.location}`
      : "-",
    "Event Detail": row.events
      ? `Name : ${row.events.name}
      Timings : ${row.events.timings}
      Date : ${row.events.date}
      Tag : ${row.events.tag}`
      : "-",
    "Booking Time": row.created_at
      ? moment(row.created_at).format("hh:mm A")
      : "-",
    "Amount Paid($)": row.total_amount,
    "Payment Mode": row.payment_mode ? row.payment_mode : "-",
    "Checked-In Status": row.checking_status ? "CheckedIn" : "Not CheckedIn",
    Status: row.checking_status ? "status" : "-",
    "Booked On": moment(row.created_at).format("DD-MM-YYYY"),
  }));

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    onPressEnter={onSearch}
                    value={searchText}
                    placeholder={`${lang("Search")} ${lang("By")} ${lang(
                      "Booking",
                    )} ${lang("ID")}, ${lang("Customer")} ${lang("Name")}`}
                  />
                  {/* <RangePicker
                    onChange={handleChangeDate}
                    disabledDate={(current) =>
                      current.isAfter(moment().subtract(1, "day"))
                    }
                  /> */}
                  <Button
                    title="Export"
                    onClick={(e) => DownloadExcel(excelData, sectionName)}
                  >
                    <i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;
                    {lang("Export")}
                  </Button>

                  {/* <Button
                    title="Export"
                    onClick={(e) => DownloadExcel(excelData, sectionName)}
                  >
                    <i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang('Export')}
                  </Button> */}
                </>
              }
            >
              <div className="total-record-cls">
                <h4 className="text-right">
                  {pagination.total
                    ? ShowTotal(pagination.total)
                    : ShowTotal(0)}
                </h4>
              </div>

              <div className="custom-tab">
                <Tabs
                  onTabClick={handleTabChange}
                  className="min-font-bold"
                  tabBarStyle={{ color: "Black" }}
                >
                  <TabPane tab={lang("All") + " " + lang("Bookings")} key="all">
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                          defaultPageSize: 10,
                          responsive: true,
                          total: pagination.total,
                          showSizeChanger: true,
                          showQuickJumper: true,
                          pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        onChange={handleChange}
                        className="ant-border-space"
                      />
                    </div>
                  </TabPane>

                  <TabPane
                    tab={lang("New") + " " + lang("Bookings")}
                    key={BOOKING_STATUS.new}
                  >
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                          defaultPageSize: 10,
                          responsive: true,
                          total: pagination.total,
                          showSizeChanger: true,
                          showQuickJumper: true,
                          pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        onChange={() => {
                          handleChange();
                          setStatus(BOOKING_STATUS.new);
                        }}
                        className="ant-border-space"
                      />
                    </div>
                  </TabPane>

                  <TabPane
                    tab={lang("Completed") + " " + lang("Events")}
                    key={BOOKING_STATUS.completed}
                  >
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                          defaultPageSize: 10,
                          responsive: true,
                          total: pagination.total,
                          showSizeChanger: true,
                          showQuickJumper: true,
                          pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        onChange={() => {
                          handleChange();
                          setStatus(BOOKING_STATUS.completed);
                        }}
                        className="ant-border-space"
                      />
                    </div>
                  </TabPane>

                  <TabPane
                    tab={lang("Cancelled") + " " + lang("Tickets")}
                    key={BOOKING_STATUS.cancelled}
                  >
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                          defaultPageSize: 10,
                          responsive: true,
                          total: pagination.total,
                          showSizeChanger: true,
                          showQuickJumper: true,
                          pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        onChange={() => {
                          handleChange();
                          setStatus(BOOKING_STATUS.cancelled);
                        }}
                        className="ant-border-space"
                      />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
