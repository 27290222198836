import { Row, Col, Card, Button, Input, Skeleton, Avatar, Image, Tooltip, Table, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import ConfirmationBox from "../../components/ConfirmationBox";

import { Badge } from 'antd';
import moment from 'moment';
import ConfirmationDelete from "../../components/ConfirmationDelete";
import SingleImageUpload from "../../components/SingleImageUpload";
import { DownloadExcel } from "../../components/ExcelFile";

import lang from "../../helper/lang";

function View() {

  const sectionName = "Event";
  const routeName = "event";

  const params = useParams();
  const { request } = useRequest();
  const { confirmDelete } = ConfirmationDelete()
  const { showConfirm } = ConfirmationBox()
  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif", "video/mp4"];

  const [refresh, setRefresh] = useState(false)
  const [list, setList] = useState({});
  const [requests, setRequests] = useState([]);
  const [image, setImage] = useState([]);
  const [billingAmount,setBillingAmount] = useState();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.reserveTableList + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data?.data?.data[0]);
        setImage(data?.data?.data[0]?.invoice);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleVenueImage = (data,id) => {
    if(!data.length) return
    setImage(data[0].url);

    console.log(data, "data>>>>>>>>>>>...")
    const payload = {
      table_id: id,
      invoice: data[0].url,
    }

    request({
      url: apiPath.editReserve ,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        setRefresh(prev => !prev)
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const excelData = requests.map(row => ({
    "Customer Name": row.customer_id?.name ? row.customer_id?.name : '-',
    "Customer Email": row.customer_id?.name ? row.customer_id?.name : '-',
    "Customer MobileNumber": row.customer_id?.country_code && row.customer_id?.mobile_number ? "+"+row.customer_id?.country_code+" "+row.customer_id?.mobile_number : '-',
    "Event Name": row.table_id?.events?.name ? row.table_id?.events?.name : '-',
    "Table Name": row.table_id.name? row.table_id.name: "-",
    "Event Date": row.table_id ? moment(row?.table_id?.events?.recurringDate).format('DD-MM-YYYY') : '-',
    "Event Day": row.table_id ? row?.table_id?.events?.recurringDay : '-',
    "Max Male Guest": row.max_male ? row.max_male : '-',
    "Max Female Guest": row.max_female ? row.max_female  : '-',
    "Deposit": row.amount ? row.amount : '-',
    "Invoices": row.invoice? row.invoice : '-',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  }));
  const fetchRequests = (id) => {
    setLoading(true);
    request({
      url: apiPath.requestsTableList + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        console.log(data.data, 5444)
        setRequests(data.data.docs);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  // const changeStatus = (id) => {
  //   const payload = {tableId: params.id}
  //   request({
  //     url: apiPath.requestsTableList + "/" + id,
  //     method: 'POST',
  //     data: payload,
  //     onSuccess: (data) => {
  //       setLoading(false);
  //       console.log(data.data, 5444)
  //       setRequests(data.data.docs);
  //     },
  //     onError: (error) => {
  //       ShowToast(error, Severty.ERROR)
  //     }
  //   })
  // }

  const TableD = [
    {
      title: `${lang("Table Name")}`,
      dataIndex: "image",
      key: "image",
      render: (_, { table_id }) => {
        return <>
        <a>{table_id.name}</a>
        </>
      },
    },
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { customer_id, _id }) => {
        return (<a className="cap avatar-text" >{customer_id ? customer_id.name : 'Not Available'}</a>);
      }
    },
    {
      title: `${lang("Email")}`,
      dataIndex: "email",
      key: "email",
      render: (_, { customer_id, _id }) => {
        return (<a className="cap avatar-text" >{customer_id ? customer_id.email : 'Not Available'}</a>);
      },
     
    },
    {
      title: `Mobile Number`,
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (_, { customer_id, _id }) => {
        return (<a className="cap avatar-text" >{customer_id ? "+"+customer_id.country_code+" "+customer_id.mobile_number : 'Not Available'}</a>);
      },
     
    },
    {
      title: `${lang(" Event Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { table_id, _id }) => {
        return (<a className="cap avatar-text" >{table_id ? table_id.events.name : 'Not Available'}</a>);
      },
     
    },
    {
      title: `${lang(" Event Date")}`,
      dataIndex: "date",
      key: "date",
      render: (_, { table_id, _id }) => {
        return (<a className="cap avatar-text" >{table_id.events.recurringDate ? moment(table_id.events.recurringDate).format('DD-MMM-YYYY') : "-"}</a>);
      },
     
    },
    {
      title: `${lang(" Event Day")}`,
      dataIndex: "date",
      key: "date",
      render: (_, { table_id, _id }) => {
        return (<a className="cap avatar-text" >{table_id.events.recurringDay ? table_id.events.recurringDay : "-"}</a>);
      },
     
    },
    {
      title: `Male guests`,
      dataIndex: "max_male",
      key: "max_male",
      render: (_, { max_male }) => {
        return (<a className="cap avatar-text">{max_male ? max_male : 0}</a>);
      },
    },
    {
      title: `Female guests`,
      dataIndex: "max_female",
      key: "max_female",
      render: (_, { max_female }) => {
        return (<a className="cap avatar-text">{max_female ? max_female : 0}</a>);
      },
    },
    {
      title: `Note`,
      dataIndex: "note",
      key: "note",
      render: (_, { notes }) => {
        return (<a className="cap avatar-text">{notes ? notes : 0}</a>);
      },
    },
    {
      title: `Deposit`,
      dataIndex: "deposit",
      key: "deposit",
      render: (_, { amount }) => {
        return (<a className="cap avatar-text">{amount ? amount : 0}</a>);
      },
    },
    

    {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
          {
            record.is_approved == "approved"?<Tag>Approved</Tag>:record.is_approved == "reject"? <Tag>Rejected</Tag>:<Tag onClick={() => showConfirm({ record: record._id, path: apiPath.reservationStatus, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>Pending</Tag>
          }
            <Tooltip title={`${lang('Delete')} ` + sectionName} color={"purple"} key={"Delete Request"}>
              <Button title="Delete" 
              onClick={() => confirmDelete({ record: record._id, path: apiPath.deleteTable,
               onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>
                <i className="fas fa-trash"></i>
              </Button>
            </Tooltip>

            {/* <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip> */}

          </>
        );
      },
    },
  ];
  const handleBilling = (id) => {
    // const {amount} = billingAmount;
    const payload = {
      table_id: id,
      invoice: image[0],
      billingAmount: billingAmount
    }

    request({
      url: apiPath.editReserve ,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        setRefresh(prev => !prev)
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    fetchData(params.id);
    fetchRequests(params.id)
  }, [refresh])

  return (
    <>
      <Row gutter={16}>
        <Col span={24} xs={24}>
          <Card title={"Table Reservation Details"}>

            {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                <h2>Table Reserved For:</h2>
               
                {list? <>
               
               <div className="view-inner-cls">
                 <h5>Customer Name:</h5>
                 <h6>
                   {list && !list?.customer_id ?
                     <Avatar style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} className="cap" size={50}>
                       {list?.customer_id?.name?.charAt(0)}
                     </Avatar>
                     :
                     <>
                     <Image className="image-radius" src={"https://sugamaya.s3.amazonaws.com/"+list?.customer_id?.image} />
                     <p>{list?.customer_id?.name}</p>
                     </>
                   }
                 </h6>
               </div>
               <div className="view-inner-cls">
                 <h5>Email:</h5>
                 <h6>
                      <p>{list?.customer_id?.email}</p>
                 </h6>
               </div>
               <div className="view-inner-cls">
                 <h5>Mobile Number:</h5>
                 <h6>
                      <p>+{list?.customer_id?.country_code + " "+list?.customer_id?.mobile_number}</p>
                 </h6>
               </div>
               <div className="view-inner-cls">
                 <h5>Event Name </h5>
                 <h6>
                      <p>{list?.table_id?.events?.name }</p>
                 </h6>
               </div>
               <div className="view-inner-cls">
                 <h5>Event Date </h5>
                 <h6>
                      <p>{ moment(list?.table_id?.events?.recurringDate).format('DD-MMM-YYYY')}</p>
                 </h6>
               </div>
               <div className="view-inner-cls">
                 <h5>Male guests:</h5>
                 <h6>
                   {list && list.max_male ?
                     <p>{list.max_male}</p>
                     :""
                   }
                 </h6>
               </div>
               <div className="view-inner-cls">
                 <h5>Female guests:</h5>
                 <h6>
                   {list && list.max_female ?
                     <p>{list.max_female}</p>
                     :""
                   }
                 </h6>
               </div>
               <div className="view-inner-cls">
                 <h5>Note:</h5>
                 <h6>
                   {list && list.notes ?
                     <p>{list.notes}</p>
                     :""
                   }
                 </h6>
               </div>
               <div className="view-inner-cls">
                 <h5>PrePaid Charges:</h5>
                 <h6>
                   {list && list.amount ?
                     <p>{list.amount}</p>
                     :0
                   }
                 </h6>
               </div>
               <div className="view-inner-cls">
                  <h5>Payment:</h5>
                  <h6>
                    {list && list.billingAmount ?
                      <Input value={list.billingAmount} disabled/>
                      :<><Input onChange={(e) => setBillingAmount(e.target.value)}/>
                  <Button onClick={() => handleBilling(list._id)}>Add Billing Amount</Button></>
                    }
                  </h6>
                </div>
               <div className="view-inner-cls">
                 <h5>Upload Invoice:</h5>
                 <SingleImageUpload value={image} fileType={FileType} imageType={'Gallary'} btnName={'Invoice'} onChange={(data)=>handleVenueImage(data, list._id)} ></SingleImageUpload>
                 {image?.length>0?<Image src={image} />: ""}
               </div>
               </>: "Not Reserved yet"}
                <Card title={"Requests for table"} >
            <Row gutter={16}>
              <Col span={12} xs={24} md={24}>

                {loading ? [1, 2, 3, 4].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={TableD}
                        dataSource={requests}
                        onChange={fetchRequests}
                        className="ant-border-space"
                      />
                    </div>
                  </div>
                }
              </Col>
            </Row>
          </Card>

                <div className="view-inner-cls float-right">
                <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>

                  <Button className="ant-btn ant-btn-primary" onClick={() =>  window.history.go(-1)}>{lang("Back")}</Button>
                </div>

              </div>
            }

          </Card>
        </Col>
      </Row>
    </>
  );
}


export default View;
